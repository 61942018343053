import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import "../styles/styles.scss"
import HamburgerNav from "./hamburger_nav"

const Nav = () => {
    return(
        <div className='navStyle' id="nav">
            <StaticImage
                onClick={() => {
                    window.location = "#home";
                }}
                style={{cursor: "pointer"}}
                className='navLogo'
                alt="Lecsa Logo"
                src="../images/lecsafreight_logo.png"
            />
            <div>
                <a className='linkStyle' href="#ground">Ground Transportation</a>
                <a className='linkStyle' href="#ocean">Ocean Transportation</a>
                <a className='linkStyle' href="#aerial">Aerial Transportation</a>
                <a className='linkStyle' href="#additional">Additional Services</a>
                <a className='linkStyle' href="#packaging">Packaging Consulting</a>
                <a className='linkStyle' href="#contact">Contact</a>
                <HamburgerNav />
            </div>
        </div>
    )
}

export default Nav