import * as React from 'react'
import Nav from './nav'
import Footer from './footer'

const Layout = ({ pageTitle, children }) => {
  return (
    <div>
      <title>{pageTitle}</title>
      <Nav />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}
export default Layout