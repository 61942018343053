import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import "../styles/styles.scss"

const Footer = () => {
    return(
        <div className='footerStyle'>
            <div className='footer'>
                 {`© ${new Date().getFullYear()}, Lecsa Freight  `}
            </div>
        </div>
    )
}

export default Footer