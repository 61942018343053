import * as React from 'react'
import Layout from '../components/layout'

const AboutPage = () => {
  return (
    <main>
      <Layout>
        <title>About Me</title>
        <h1>About Me</h1>
        <p>Hi there! I'm the proud creator of this site, which I built with Gatsby.</p>
      </Layout>
    </main>
  )
}

export default AboutPage