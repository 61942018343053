import React, { useState, useEffect } from 'react';
import "../styles/styles.scss"
import { AiOutlineMenu } from "react-icons/ai"

const HamburgerNav = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    return(
        <>
            <AiOutlineMenu 
                size={40} 
                className='topHamburger'
                onClick={() => setShowDropdown(!showDropdown)}
            />
            {showDropdown &&
                <div className="navDropdown">
                    <div 
                        className='dropdownItem' 
                        onClick={() => {
                            window.location = "#ground";
                            setShowDropdown(false);
                        }}
                    >
                        Ground Transportation
                    </div>
                    <div 
                        className='dropdownItem' 
                        onClick={() => {
                            window.location = "#ocean";
                            setShowDropdown(false);
                        }}
                    >
                        Ocean Transportation
                    </div>
                    <div 
                        className='dropdownItem' 
                        onClick={() => {
                            window.location = "#aerial";
                            setShowDropdown(false);
                        }}
                    >
                        Aerial Transportation
                    </div>
                    <div 
                        className='dropdownItem' 
                        onClick={() => {
                            window.location = "#additional";
                            setShowDropdown(false);
                        }}
                    >
                        Additional Services
                    </div>
                    <div 
                        className='dropdownItem' 
                        onClick={() => {
                            window.location = "#packaging";
                            setShowDropdown(false);
                        }}
                    >
                        Packaging Consulting
                    </div>
                    <div 
                        className='dropdownItem' 
                        onClick={() => {
                            window.location = "#contact";
                            setShowDropdown(false);
                        }}
                    >
                        Contact
                    </div>
                </div>
            }
        </>
    )
}

export default HamburgerNav
